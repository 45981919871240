
import '@/base/table2CSV';

class Tools {

    canAccess (user, type, method, endpoint, defValue=true) {
        if (!user || !user.access_group) return true;
        let p = user.access_group.permissionsMerge || {};
        if (typeof p[type] === 'boolean') return p[type]
        if (defValue){
            var def = (((p[type] || {}).defaults || {}).methods || {})[method];
            if (def===undefined) def = true;
        }else{
            var def = false;
        }
        if (!endpoint) return def
        endpoint = endpoint.replace(/(<[^:]+)?:[^>/]+>?/g, ':p')
        var perm = ((p[type] || {})[endpoint] || {}).methods
        if (typeof perm === 'object') {
            return (typeof perm === 'undefined') ? def: perm[method]
        }
        if (typeof perm === 'boolean'){
            return perm
        }
        if (!perm) {
            return def
        }
        return (typeof perm[method] === 'undefined') ? def: perm[method]
    }

    mergeDateAndTime (d, t, z = 'utc') {
        if(z == 'local')
            return moment.utc(d+' '+t, 'YYYY-MM-DD HH:mm:ss').local().format('YYYY-MM-DDTHH:mm:ss');
        return moment(d+' '+t, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
    }

    getDate (d) {
        return moment(d).format("DD/MM/YYYY");
    }

    getTime (t) {
        return moment(t).format("HH:mm:ss");
    }

    getDateTime (d) {
        return moment(d).format("DD/MM/YYYY HH:mm");
    }

    getLocalDate (d, format) {
        if (format) return moment(d).local().locale('es').format(format)
        return moment(d).local().locale('es').format('DD MMM YYYY')
    }


    getLocalTime (d) {
        return moment(d).local().locale('es').format('HH:mm')
    }

    getLocalDateTime (d) {
        return moment.utc(d).local().locale('es').format('DD MMM YYYY HH:mm')
    }


   	dateIsAfter (date1, date2){
   		if(moment(date1).isAfter(date2))
   			return true;
   		return false;

   	}

   	diffHours (date1, date2) {
   		return moment(date1).diff(moment(date2))/3600000;
   	}

    downloadReportCSV (id, fileName) {
        let table = $('#' + id);
        let csv = table.table2CSV({
            delivery: 'value'
        });
        this.download(fileName,  encodeURIComponent(csv))
    }

    downloadReport (id, fileName) {
        let table = document.getElementById(id);
        let worksheet = table.innerHTML;
        this.download(fileName, escape(worksheet))

    }

    download (filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + text);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    getValue (v){
        if (v && v.value!=undefined && v.value!=null){
            return v.value;
        }
        return v;
    }

    getBGColor (v){
        if (v && v.bgColor) return v.bgColor;
        return null;
    }

    getAlign (v) {
        if (v && v.align) return v.align;
        return 'left';
    }

    setAutoSizeTextArea (t){
        t.addEventListener('keyup', function() {
            tools.autoSizeTextArea(this);
        }, false);
    }

    autoSizeTextArea (self){
        self.style.overflow = 'hidden';
        self.style.height = 0;
        self.style.height = self.scrollHeight + 'px';
        if (parseInt(self.style.height)<40) self.style.height = '40px';
    }

    normalize (s) {
        if (!s) return s;
        if (s.normalize != undefined) {
            s = s.normalize ("NFKD");
        }
        s = s.toString().replace(/æ/g, "ae");
        s = s.replace(/[\'`´]/g, "");
        s = s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') //all chars literal
        return s.replace(/[\u0300-\u036F]/g, "");
    }

    joinText (list, joiner, lastJoiner) {
        if (list.length==1) return list[0];
        if (list.length==2) {
            return list.join(lastJoiner);
        }
        if (list.length>2) {
            let res = '';
            for (let i in list) {
                res += list[i];
                if (i<list.length-2) {
                    res += joiner;
                } else if (i==list.length-2) {
                    res += lastJoiner;
                }
            }
            return res;
        }
    }

    objectsDiff (a, b) {
        let s1 = JSON.stringify(a);
        let s2 = JSON.stringify(b);
        return s1 === s2;
    }

    toNumber (value, decimals, separator) {
        let s = separator;
        if ((s==null || s==undefined) && this.me && this.me.DecimalSeparator) s = this.me.DecimalSeparator;
        if (s==null || s==undefined) s = '';
        if (!value) return value;
        return value.toFixed(decimals).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, s);
    }

    toTitleCase (str) {
        return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    setReportValues (self) {
        for (let field of self.fields) {
            if (field.defValue) {
                self.record[field.name] = field.defValue;
            } else {
                self.record[field.name] = null;
            }
        }
        if (self.options) {
            for (let field of self.fields) {
                if (self.options[field.name]) {
                    field.defValue = self.options[field.name];
                    self.record[field.name] = self.options[field.name];
                }
            }
        }
        self.fields = Object.assign([], self.fields);
    }

    getAbmFields (fields) {
        let res = []
        for (let f of fields) {
            if (typeof f.editor === 'object') continue;
            //if (f.editor === 'component') continue;
            //if (Array.isArray(f.editor)) continue;
            //if (f.editor === 'tab') continue;
            if (f.name) {
                let ff = _.cloneDeep(f)
                ff.label = f.label? f.label : f.name;
                ff.sortOrder = 1;
                ff.editor = f.editor ? f.editor : 'text';
                ff.type = f.type ? f.type: 'text';
                if (f.classes) ff.classes ? f.classes : '';
                res.push(ff);
            } else {
                res.push({ name: f, label: f, editor: 'text', sortOrder: 1 });
            }
        }
        return res;
    }

    async fetchTable (tableName, endpoint, filters, refresh, record, showClosed) {
        if (!refresh && !filters) {
            let res = api.getFromStore(tableName);
            if (res) return res;
        }
        let e = endpoint ? endpoint: '/api/' + tableName + '/';
        let f = filters;
        if (typeof(filters) === 'function' && record) {
            filters = filters(record);
        }
        if (this.hasClosed && filters) {
            filters['IncludeClosed'] = showClosed;
        }
        if (filters) {
            filters =  {filters: JSON.stringify(filters)};
        }
        let res = await api.get(e, filters);
        if (res) {
            if (!filters) {
                api.setTableToStore(tableName, res);
            }
            return res;
        }
        return [];
    }

    async calculateFieldOptionsByField (field, fo, serverSide, record, showClosed) {
        if (Array.isArray(field.options) || (typeof(field.options) === 'function')) {
            let options = field.options;
            if (typeof(field.options) === 'function') {
                options = field.options();
            }
            fo[field.name] = _.map(_.filter(options, (r)=> !r.skip), function(o) {
                let value = o;
                let label = o;
                let bgColor = null;
                let color = '#000000';
                let disabled = null;
                if (o.value!=null && o.value!=undefined) value = o.value;
                if (o.label) label = o.label;
                if (o.bgColor) bgColor = o.bgColor;
                if (o.color) color = o.color;
                if (o.disabled) disabled = o.disabled;
                return {
                    label: label,
                    value: value, 
                    bgColor: bgColor, 
                    color: color, 
                    disabled: disabled, 
                    sortBy: o.sortBy,
                }
            })
        } else if (field.relation && !serverSide) {
            let rows = await this.fetchTable(field.relation, field.endpoint, field.endpointFilters, false, record, showClosed);
            let opts = []
            for (let row of rows) {
                let skip = false;
                if (field.filters) {
                    if (typeof(field.filters) === 'object') {
                        for (let f in field.filters) {
                            if (row[f] != field.filters[f]) {
                                skip = true;
                            }
                        }
                    } else if (typeof(field.filters) === 'function') {
                        if (!field.filters(row, record)) {
                            skip = true;
                        }
                    }
                }
                if (!skip) {
                    if (typeof(field.optionLabels) === 'function') {
                        let label =  field.optionLabels(row);
                        opts.push({label: label, value: row.id});
                    } else {
                        opts.push({label: row[field.optionLabels], value: row.id});
                    }
                }
            }
            fo[field.name] = opts
        }
    }

    async calculateFieldOptions (abmFields, serverSide, record, showClosed, onlyHeaders, fieldName, options) {
        let fo = {}
        if (options) fo = options;
        let promises = [];
        for (let field of abmFields) {
            if (fieldName && field.name != fieldName) continue;
            await this.calculateFieldOptionsByField (field, fo, serverSide, record, showClosed);
            if (!onlyHeaders) {
                if (field.rowFields && Array.isArray(field.editor) && Array.isArray(field.rowFields)) {
                    for (let rowField of field.rowFields) {
                        await this.calculateFieldOptionsByField (rowField, fo, serverSide, record, showClosed);
                    }
                }
                if (field.editor == 'tab') {
                    for (let rowField of field.fields) {
                        await this.calculateFieldOptionsByField (rowField, fo, serverSide, record, showClosed);
                    }
                }
            }
        }
        return fo;
    }

    getDisplayValue (record, field, serverSide, fieldOptions) {
        if (record[field.name]==null || record[field.name]==undefined) return '';
        if (record[field.name]===true) return 'YES';
        if (record[field.name]===false) return 'NO';
        if (field.dateFormat && record[field.name]) {
            return moment(record[field.name]).format(field.dateFormat);
        }
        if (field.relation || field.options) {
            if (serverSide) {
                let key = field.name + '_' + field.optionLabels;
                if (record[key]) return record[key];
            }
            if (!fieldOptions) return record[field.name];
            if (!fieldOptions[field.name]) return null
            let opt = _.find(fieldOptions[field.name], (opt) => opt.value == record[field.name])
            if (opt) return opt.label;
            if (field.setRelationTo && record[field.setRelationTo] && record[field.setRelationTo][field.optionLabels]) {
                return record[field.setRelationTo][field.optionLabels];
            }
            if (field.setRelationTo && record[field.setRelationTo] && record[field.setRelationTo].Name) {
                return record[field.setRelationTo].Name;
            }
            return record[field.name];
        }
        return ""+record[field.name];
    }

    getMaxLength (tableSpec, fieldName, rowFieldName) {
        if (!tableSpec) return;
        if (rowFieldName) {
            if (tableSpec[fieldName] && tableSpec[fieldName][rowFieldName]) {
                return tableSpec[fieldName][rowFieldName];
            }
        } else {
            if (tableSpec[fieldName]) {
                return tableSpec[fieldName];
            }
        }
    }

    handleMessage (response, handleError) {
        let msg;
        if (typeof response === 'string') msg = response;
        //if (!msg && response.responseJSON) msg = response.responseJSON;
        //if (!msg && response.responseText) msg = response.responseText;
        //if (!msg && response.statusText) msg = response.statusText;
        if (!msg) msg = response;
        if (handleError) {
            return msg;
        } else {
            alert(msg);
        }
    }

    responseHelper (status, response, handleError) {
        if (status==401) {
            if (window.location.hash == '#/login') {
                return tools.handleMessage('Incorrect User or Password', handleError);
            } else {
                window.location.replace(window.location.origin + '/#/login')
            }
        } else if (status==400){
            return tools.handleMessage(response, handleError);
        } else if (status==420){
            return tools.handleMessage('Integrity Error', handleError);
            //self.dependencies = true;
        } else if (status==403){
            return tools.handleMessage('Action not allowed', handleError);
        } else if (status==500){
            return tools.handleMessage('Server Error', handleError);
        } else {
            return tools.handleMessage('Server Error', handleError);
            //reject(error);
        }
    }

    getWeekDayName (w) {
        return {
            0: 'Monday',
            1: 'Tuesday',
            2: 'Wednesday',
            3: 'Thursday',
            4: 'Friday',
            5: 'Saturday',
            6: 'Sunday'
         }[w];
    }

    getActive (tabId, field, currentTab, getInvalidClass) {
        let res = '';
        if (currentTab==tabId) res += 'active ';
        if (field && getInvalidClass[field]) res += ' tab-alert';
        return res;
    }

    abmList (self) {
        if (self.search) {
            let values = self.search.split(' ')
            return _.filter(self.dataList, (r) => {
                for (let value of values){
                    let found = false;
                    let re = new RegExp(tools.normalize(value), 'i')
                    for (let f of self.abmFields) {
                        let displayValue;
                        if (self.listValues) {
                            //displayValue = self.listValues[[f.name, r[f.name]]]
                        } else {
                            displayValue = tools.getDisplayValue(r, f, self.serverSide, self.fieldOptions)
                        }
                        displayValue = tools.getDisplayValue(r, f, self.serverSide, self.fieldOptions)
                        if (!displayValue) displayValue = r[f]
                        if (displayValue){
                            let m = tools.normalize(displayValue).match(re)
                            if (m) found = true;
                        }
                    }
                    if (!found) return false;
                }
                return true;
            })
        }
        return self.dataList;
    }

    getTime () {
        if (!this.t)  {
            this.t = new Date().getTime();
            return 0;
        }
        this.oldT = this.t;
        this.t = new Date().getTime();
        return this.t - this.oldT;
    }

    isNormalInteger (str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    getBases (self) {
        let res = {};
        for (let id in self) {
            if (tools.isNormalInteger(id)) {
                res[id] = self[id];
            }
        }
        return res;
    }

    ifText (s) {
        if (!s) return false;
        if (s.replace(/<p>|<\/p>|<br>/g, '').length>0) return true;
    }

    serverTypes (fields, options) {
        //console.log(fields, options)
        let res = [];
        let types = {
            'Text': 'text-area',
            'String': 'text',
            'Float': 'number',
            'Integer': 'number',
            'DateTime': 'datetime',
            'Date': 'date',
            'Boolean': 'checkbox',
            'Collection': '[]'
        }
        let closed = false;
        for (let fieldName in fields) {
            let field = fields[fieldName]
            let editor = field.type;
            if (types[editor]) editor = types[editor];
            let label = fieldName;
            if (fieldName.match(/[A-Z][a-z]+|[0-9]+/g)) {
                label = fieldName.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
            }
            if (fieldName == 'Closed') {
                closed = {name: fieldName, editor: editor, label: label, hideFromList: true};
            } else {
                let f = {name: fieldName, editor: editor, label: label};
                if (editor == 'date') {
                    f.dateFormat = 'DD/MM/YYYY';
                }
                if (editor == 'datetime') {
                    f.dateFormat = 'DD/MM/YYYY HH:mm';
                }
                if (editor == 'number') {
                    f.decimal = 2;
                }
                if (editor == 'text-area') {
                    f.columns = 12;
                }
                if (field.relation) {
                    f.editor = 'vue-select';
                    f.relation = field.relation;
                    f.optionLabels = 'Name';
                    f.label = tools.toTitleCase(field.relation);
                }
                if (field.options) {
                    f.editor = 'select';
                    f.options = _.map(field.options, (r) => {
                        return {value: r, label: tools.toTitleCase(r)}
                    });
                }
                if (field.type == 'Collection') {
                    f.editor = [];
                    f.rowFields = tools.serverTypes(field.row_fields);
                }
                if (field.hide) f.hideFromList = true;
                if (field.hide_list) f.hideFromList = true;
                if (field.hide) f.hidden = true;
                if (field.label) f.label = field.label;
                if (field.hide_list) f.hideFromList = true;
                if (field.read_only) f.readonly = true;
                if (field.required) f.required = true;
                res.push(f)
            }
        }
        res.sort(function(a, b) {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
        })
        if (options && options.fieldsOrder) {
           res.sort(function(a, b){
                let sa = options.fieldsOrder.indexOf(a.name);
                let sb = options.fieldsOrder.indexOf(b.name);
                if (sa==-1) sa = 100;
                if (sb==-1) sb = 100;
                return sa - sb;
            });
        }
        if (options && options.extraFields) {
            for (let field of options.extraFields) {
                res.push(field);
            }
        }

        if (closed) {
            res.push(closed)
        }
        if (options && options.fieldsDataReplace) {
            for (let fieldName in options.fieldsDataReplace) {
                let fieldIndex = _.findIndex(res, (f) => f.name == fieldName);
                if (fieldIndex>-1) {
                    for (let data in options.fieldsDataReplace[fieldName]) {
                        res[fieldIndex][data] = options.fieldsDataReplace[fieldName][data];
                    }
                }
            }
        }
        return res;
    }

    plainObject (record) {
        let r = Object.assign({}, record);
        let toDelete = [];
        for (let fieldName in record) {
            if (Array.isArray(record[fieldName])) {
                r[fieldName] = [];
                for (let row of record[fieldName]) {
                    let newRow = this.plainObject(row);
                    r[fieldName].push(newRow);
                }
            } else if (typeof record[fieldName] == 'object') {
                if (record[fieldName]) {
                    r[fieldName] = this.plainObject(record[fieldName]);
                }
            }
            if (fieldName[0] == '$') toDelete.push(fieldName);
        }
        for (let fieldName of toDelete) {
            delete r[fieldName];
        }
        //console.log(r)
        return r;
    }

    plainArray (rows) {
        let r = [];
        for (let row of rows) {
            let newRow = this.plainObject(row);
            r.push(newRow);
        }
        return r;
    }

    clean (self) {
        if (!self) return;
        if (self.fields) {
            for (let field of self.fields) {
                if (field && (Array.isArray(field.editor) || field.rowFields)){
                    for (let row of self[field.name]) {
                        tools.clean(row);
                    }
                } else if (field && typeof field.editor == 'object') {
                    tools.clean(self[field.name]);
                }
            }
        }
        delete self['fields'];
        delete self['abmFields'];
        delete self['arrayFields'];
        delete self['fieldOptions'];
        delete self['tabList'];
        delete self['tableSpec'];
        delete self['invalid'];
        delete self['_loaded'];
        delete self['_cache'];
        delete self['$parent'];
        delete self['_copy'];
        delete self['_id'];
        delete self['_computedGetters'];
        delete self['gettersNames'];
    }

    cleanBeforeSave (record) {
        for (let field of record.fields) {
            if (field && (Array.isArray(field.editor) || field.rowFields)){
                for (let row of record[field.name]) {
                    this.cleanBeforeSave(row);
                }
            } else if (field && typeof field.editor == 'object') {
                this.cleanBeforeSave(record[field.name]);
            }
        }
        delete record['fields'];
        delete record['abmFields'];
        delete record['arrayFields'];
        delete record['fieldOptions'];
        delete record['tabList'];
        delete record['tableSpec'];
        delete record['invalid'];
        delete record['_loaded'];
        delete record['_id'];
        delete record['_computedGetters'];
        delete record['_cache'];
        delete record['$parent'];
        delete record['me'];
        delete record['_modified'];
        delete record['serverSide'];
        delete record['search'];
        delete record['beforeSave'];
        delete record['canAdd'];
        delete record['userCanEdit'];
        delete record['userCanDelete'];
        delete record['evalDelete'];
        delete record['evalEdit'];
        delete record['afterEdit'];
        delete record['showClosed'];
        delete record['gettersNames'];
        delete record['attachList'];
        delete record['attached'];
        delete record['endpoint'];
        delete record['notifications'];
        delete record['processingAttach'];
        delete record['tableName'];
        //this.removeGetters(record);
    }

    removeGetters (recordClass, record) {
        const proto = Object.getPrototypeOf (recordClass);
        const names = Object.getOwnPropertyNames (proto);
        const getters = names.filter (name => name.substring(0, 3)=='$$_');
        for (let getterName of getters) {
            delete record[getterName.replace('$$_', '')]
        }
    }

    checkFields (self, record) {
        let required = false;
        self.invalid = {};
        self.invalid._tabData = false;
        let fields = _.cloneDeep(self.fields);
        if (self.tabList) {
            for (let tab of self.tabList) {
                for (let field of tab.fields) {
                    field.tabName = tab.name;
                    fields.push(field);
                }
            }
        }
        for (let p in fields){
            let needsRequired = fields[p].required
            if (fields[p].requiredCallback) {
                needsRequired = fields[p].requiredCallback(self);
            }
            if (needsRequired) {
                let value = record[fields[p].name];
                if (value==null || value==undefined || value=="" ||
                    (fields[p].editor=='date' && value=='Invalid date')){
                    if (fields[p].tabName) {
                        self.invalid[fields[p].tabName] = true;
                    } else if (!fields[p].headerField){
                        self.invalid._tabData = true;
                    }
                    self.invalid[fields[p].name] = true;
                    required = true;
                }
            }
        }
        self.invalid = Object.assign({}, self.invalid);
        return required;
    }

}

window.tools = new Tools();
