<template>
  <div id="app">
    <nav-bar v-if="loaded && me.id"></nav-bar>
    <router-view :key="$route.fullPath" v-if="loaded">
        </router-view>
    <block-ui v-if="processing || !loaded"/>
  </div>
</template>

<script>
import '@/css/style.css';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { mapState } from "vuex";
import es from '@/languages/es.json'
import en from '@/languages/en.json'
let sl = require('@/languages/system_languages.json');
const languages = {es: Object.assign(es, sl.es), en: Object.assign(en, sl.en)};

export default {
    name: "App",
    components: {},
    data () {
        return {
          loaded: false,
        };
    },
    computed: {
        ...mapState({
            me: state => state.main.me,
            processing: state => state.main.processing,
        }),
        modified () {
            if (this.$store.getters.modified) {
                return true;
            }
        }
    },
    created () {
        this.setLanguage();
        const tr = (text) => {
            try {
                if (text in languages['en']) return languages['en'][text];
            } catch (err) {
                debugger
            }
            return text
        }
        window.tr = tr;
        Vue.mixin({
            methods: {
                tr: tr
            }}
        )

    },
    async mounted () {
        setInterval(() => {
            if (api.processing!=vueTools.processing) {
                vueTools.setProcessing(api.processing)
            }
        }, 100);
        let self = this;
        window.onbeforeunload = function (e) {
            if (self.modified) {
                e = e || window.event;
                if (e) {
                    e.returnValue = 'Sure?';
                }
                return 'Sure?';
            }
        };
        let promises = [];
        if (!api.currentUser) {
            this.$store.commit('setMe', await api.getCurrentUser());
        }
        await Promise.all(promises);
        this.loaded = true;
    },
    methods: {
        setLanguage(lang) {
            if (!lang) {
                let navLang = (navigator.userLanguage || navigator.language).substring(0,2)
                if (navLang in languages) {
                    lang = navLang
                } else {
                    lang = 'en';
                }
            }
            window.lang = lang;
            this.lang = lang;
        },

    }
};
</script>
