<template>
    <section class="home-section">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <report-window
                endpoint="/reports/lid_control"
                :perPage="10"
                :fields="fields"
                :title="title"
                :headers="headers"
                :removeHeaders="removeHeaders"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
                :columnsClasses="columnsClasses"
                :runMounted="options? options.runMounted: false"
            ></report-window>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import FlightInfo from "@/components/flight/FlightInfo";
const moment = require("moment");
export default {
    name: "lid-control-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
    },
    async mounted () {
        let res = await api.get('/reports/get_max_lid');
        if (res) {
            this.maxLid = res;
        }
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
    },
    data() {
        return {
            maxLid: 1.09,
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: "FileDate",
                    editor: "date",
                    label: "File Date",
                    columns: 2,
                    required: true,
                    defValue: moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightNumber",
                    label: "Flight Designator",
                    columns: 2,
                },
                {
                    name: "FlightFromDate",
                    editor: "date",
                    label: "Flight Date From",
                    columns: 2,
                    defValue: moment(new Date()).format('YYYY-MM-DD')
                },
                {
                    name: "FlightToDate",
                    editor: "date",
                    label: "Flight Date To",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "Origin",
                    editor: "text",
                    label: "Origin",
                    columns: 2
                },
                {
                    name: "Destination",
                    editor: "text",
                    label: "Destination",
                    columns: 2
                },
                {
                    name: "OnlyErrors",
                    editor: "checkbox",
                    label: "Only Errors",
                    columns: 2
                },
            ],
            columnsClasses: {
            },
            title: "Lid Control",
            removeHeaders: [],
            headers: {
            },
            templates: [
                {name: 'File Date', callback: function(row) {
                        if (!row['File Date']) return '';
                        return moment(row['File Date']).format('DD MMM');
                    }
                },
            ],
            columnClick: {
                number: this.showFlight,
            },
            fieldClasses: {
                Lid: (row) => {
                    if (parseFloat(row.Lid) / parseFloat(row.Cap) > this.maxLid) return 'exclamation-alert';
                },
                Cap: (row) => {
                    if (row.Cap > row.Lid) return 'exclamation-alert';
                    if (parseFloat(row.Cap) / parseFloat(row.Lid) > this.maxLid) return 'exclamation-alert';
                },
                'Cap +1': (row) => {
                    if (row.Cap != row['Cap +1']) return 'exclamation-alert';
                },
                ARO_Lid: (row) => {
                    if (row.ARO_Lid != row.Lid) return 'exclamation-alert';
                },
                ADJ_Lid: (row) => {
                    if (row.ADJ_Lid != row.Lid) return 'exclamation-alert';
                },
            },
        };
    },
  };
</script>
