const uuidv1 = require('uuid/v1');

class BaseModel {

    constructor ({fields, record, parent, gettersNames, tableName}) {
        let self = this;
        return (async () => {
            if (parent) {
                self.setParent(parent);
            }
            self._id = uuidv1();
            self._loaded = false;
            self._cache = {};
            self.fields = fields;
            if (!self.fields && self.constructor.moduleName) {
                self.fields = await this.constructor.getFields();
            }
            if (!self.fields) {
                self.serverFields = await api.get('/api/get_table_fields/' + tableName);
                self.fields = tools.serverTypes(self.serverFields)
            }
            await self.initFieldProperties();
            self.invalid = {};
            self.tableSpec = null;
            if (record) {
                await self.init(record);
            } else {
                await self.create();
            }
            self.fieldOptions = await tools.calculateFieldOptions(self.fieldsList, null, self, null, null);
            self.gettersNames = gettersNames;
            self._computedGetters = {};
            self.initComputedGetters();
            self.setComputedGetters();
            return self;
        })();
    }

    get tabFields () {
        let res = [];
        let tabs = _.filter(this.fields, (r) => r.editor == 'tab');
        for (let tab of tabs) {
            if (!tab.fields) continue;
            for (let field of tab.fields) {
                res.push(field);
            }
        }
        return res;
    }

    static async getFields () {
        //const params = importModule('base/tables', this.moduleName);
        let options = await getModuleFieldsOptions(this.moduleName);
        return options.fields;
    }

    get abmFields () {
        let fields = _.filter(this.fields, (f) => {
            if (f.hideCondition) {
                if (f.hideCondition(this)) {
                    return false;
                }
            }
            return true;
        })
        return tools.getAbmFields(fields);
    }

    get headerFields () {
        let res = [];
        for (let f of this.fields) {
            if (f.editor=='tab') continue;
            if (f.editor=='button') continue;
            if (f.editor=='component') continue;
            if (typeof f.editor === 'object') continue;
            if (Array.isArray(f.editor)) continue;
            res.push(f.name);
        }
        return res;
    }

    get fieldsList () {
        return Object.assign([], this.fields).concat(this.tabFields);
    }

    initGetters () {
        if (!this.gettersNames) return;
        const proto = Object.getPrototypeOf (this);
        const names = Object.getOwnPropertyNames (proto);
        const getters = names.filter (name => name.substring(0, 2)=='$$_');
        for (let getterName of getters) {
            let name = getterName.replace('$_', '')
            Object.defineProperty(this, name, {
                enumerable : true,
                configurable : true,
                get: () => {
                    //return this[getterName];
                    return this.setGetter(name, getterName);
                }
            });
        }
    }

    setGetter (name, getterName) {
        if (!_cache.objects[this._id]) {
            _cache.objects[this._id] = {};
        }
        if (!_cache.objects[this._id][name]) {
            _cache.objects[this._id][name] = {value: null, status: false, update: true};
        }
        let res = {};
        if (_cache.objects[this._id][name].update) {
            _cache.objects[this._id][name].status = true;
            res = this[getterName];
            _cache.objects[this._id][name].value = _.cloneDeep(res);
        } else {
            res = _.cloneDeep(_cache.objects[this._id][name].value);
        }
        _cache.objects[this._id][name].update = false;
        _cache.objects[this._id][name].status = false;
        return res;
    }


    async initFieldProperties () {
        let self = this;
        for (let field of this.fieldsList) {
            if (!field.name) continue;
            if (Array.isArray(field.editor) || field.rowFields) {
                this[field.name] = [];
            } else if (typeof field.editor == 'object') {
                this[field.name] = await this.newClassObject(field);
            } else {
                this['$' + field.name] = {value: null, getters: {}};
                Object.defineProperty(this, field.name, {
                    enumerable : true,
                    configurable : true,
                    set: (value) => {
                        this.setValue({fieldName: field.name, value});
                    },
                    get: () => {
                        this.checkCache(field.name)
                        return this['$' + field.name].value;
                    }
                });
                if (field.setRelationTo) {
                    this[field.setRelationTo] = {};
                }
            }
        }
    }

    checkCache (fieldName) {
        for (let _id in _cache.objects) {
            let obj = _cache.objects[_id];
            if (!_cache.objects[_id]) continue;
            for (let getter in obj) {
                if (getter == fieldName) continue
                if (obj[getter].status) {
                    if (!this['$' + fieldName].getters[getter]) this['$' + fieldName].getters[getter] = [];
                    if (this['$' + fieldName].getters[getter].indexOf(_id)==-1) {
                        this['$' + fieldName].getters[getter].push(_id)
                    }
                }
            }
        }
    }

    updateGetters (fieldName) {
        for (let getter in this['$' + fieldName].getters) {
            for (let _id of this['$' + fieldName].getters[getter]) {
                if (!_cache.objects[_id]) continue;
                _cache.objects[_id][getter].update = true;
            }
        }
    }

    async setValue ({fieldName, value}) {
        let oldVal = this['$' + fieldName].value;
        this['$' + fieldName].value = value;
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let r = await this.afterEditField(field, value, oldVal);
        r.push({ params: [ fieldName ], value: value, action: 'set'});
        return r;
    }

    async afterEditField (field, newVal, oldVal) {
        let res = [];
        if (this._loaded) {
            this.updateGetters(field.name)
            let r = await this.edit(field.name, newVal, oldVal);
            if (r) res.push(r)
            if (field.afterEdit && (newVal || oldVal)) {
                let r = await field.afterEdit(this, newVal, oldVal);
                if (r) res.push(r)
            }
            if (this.afterEdit && this.afterEdit()[field.name] && (newVal || oldVal)) {
                let r = await this.afterEdit()[field.name](this, newVal, oldVal);
                if (r) res.push(r)
            }
            this.setComputedFields();
            this.setComputedGetters(field.name);
        }
        return res;
    }

    async afterEditRow (field) {
        let res = [];
        if (this._loaded) {
            let r = await this.edit(field.name);
            if (r) res.push(r)
            if (field.afterEdit) {
                let r = await field.afterEditField(this);
                if (r) res.push(r)
            }
            this.setComputedFields();
        }
        return res;
    }

    async addRow ({fieldName, values}){
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let childRow = await this.newRow(field);
        if (values) {
            for (let f in values) {
                childRow[f] = values[f];
            }
        } else {
            await childRow.create();
        }
        this[field.name].push(childRow);
        let r = await this.afterEditRow(field);
        r.push({
            params: [ fieldName, this[fieldName].length - 1 ],
            value: this[fieldName][this[fieldName].length - 1],
            action: 'push'
        });
        return r;
    }

    async removeRow ({fieldName, rowNr}) {
        this[fieldName].splice(rowNr, 1);
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let r = await this.afterEditRow(field);
        r.push({ params: [ fieldName, rowNr ], value: null, action: 'remove'});
        return r;
    }

    async closeRow ({fieldName, rowNr}) {
        await this[fieldName][rowNr].setValue({fieldName: 'Closed', value: true})
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let r = await this.afterEditField(field, false, true);
        r.push({ params: [ fieldName, rowNr ], value: null, action: 'remove'});
        return r;
    }

    updateCached (name) {
        return;
        for (let id in this._cache) {
            if (this._cache[id].dependsOn.indexOf(name)>-1) {
                this._cache[id].value = null;
            }
        }
        if (this.$parent) {
            this.$parent.updateCached(this.constructor.name);
        }
    }

    setParent (obj) {
        Object.defineProperty(this, '$parent', {enumerable: false, value: obj, configurable: true});
    }

    async newObjectByName (fieldName, obj) {
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        if (field) {
            let r = await this.newClassObject(field, obj);
            return r;
        }
        r._loaded = true;
    }

    async newObject (field, obj) {
        let r = await this.newClassObject(field, obj);
        r._loaded = true;
        return r;
    }

    async newClassObject (field, obj, copy) {
        if (field.objectClass) {
            let objectClass = require(`@/base/model/${field.objectClass}`).default;
            let r;
            if (copy) {
                r = await objectClass.copy({record: obj, parent: this});
            } else {
                r = await new objectClass({record: obj, parent: this});
            }
            return r;
        }
        let r;
        if (copy) {
            r = await BaseModel.copy({fields: field.fields, record: obj, parent: this});
        } else {
            r = await new BaseModel({fields: field.fields, obj, parent: this});
        }
        return r;
    }

    async newRowByName (fieldName, obj) {
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        if (field) {
            let r = await this.newRow(field, obj);
            return r;
        }
        r._loaded = true;
    }


    async newRow (field, record) {
        let r = await this.newClassRow(field, record);
        r._loaded = true;
        return r;
    }

    async newClassRow (field, record, copy) {
        if (field.rowClass) {
            let rowClass = require(`@/base/model/${field.rowClass}`).default;
            let r;
            if (copy) {
                r = await rowClass.copy({record, parent: this});
            } else {
                r = await new rowClass({record, parent: this});
            }
            return r;
        }
        let r;
        if (copy) {
            r = await BaseModel.copy({fields: field.rowFields, record, parent: this});
        } else {
            r = await new BaseModel({fields :field.rowFields, record, parent: this});
        }
        //this.setParent(r);
        return r;
    }

    async init (record) {
        let arrayFields = record.fields;
        if (!arrayFields) arrayFields = record;
        for (let i in arrayFields) {
            let fieldName = i;
            if (record.fields) fieldName = arrayFields[i].name;
            let field = _.find(this.fieldsList, (f) => f.name == fieldName);
            if (field && (Array.isArray(field.editor) || field.rowFields)){
                this[field.name] = [];
                for (let row of record[field.name]) {
                    let childRow = await this.newClassRow(field, row);
                    this[field.name].push(childRow)
                }
            } else if (field && field.editor && typeof field.editor == 'object') {
                let o = await this.newClassObject(field, record[fieldName]);
                this[fieldName] = o;
            } else {
                this[fieldName] = record[fieldName];
            }
        }
        this._loaded = true;
        this.setModified(false);
        this.setComputedFields();
    }

    static async copy ({fields, record, parent}) {
        let r = await new this({fields, parent});
        tools.clean(record)
        r._loaded = true;
        r.id = null;
        let arrayFields = r.fields;
        for (let field of r.fields) {
            let fieldName = field.name;
            let promises = [];
            if (field && (Array.isArray(field.editor) || field.rowFields)){
                r[field.name] = [];
                for (let row of record[field.name]) {
                    let childRow = await r.newClassRow(field, row, true);
                    r[field.name].push(childRow)
                }
            } else if (field && typeof field.editor == 'object') {
                let o = await r.newClassObject(field, record[fieldName], true);
                r[fieldName] = o;
            } else {
                r[fieldName] = record[fieldName];
                if (field.setRelationTo) {
                    r[field.setRelationTo] = Object.assign({}, record[field.setRelationTo]);
                }
            }
        }
        r._loaded = true;
        r.setComputedFields();
        r.setComputedGetters();
        return r;
    }

    async create () {
        for (let f of this.fieldsList) {
            if (f.defValue) {
                await this.setValue({fieldName: f.name, value: f.defValue});
            }
        }
        this.setComputedFields();
        this.setComputedGetters();
        /*if (customTools && customTools.setDefault) {
            customTools.setDefault(this);
        }*/
        //this._loaded = true;
    }

    checkFields () {
        return tools.checkFields(this, this);
    }

    get arrayFields () {
        let res = [];
        for (let f of this.fieldsList) {
            if (f.hidden) continue;
            if (Array.isArray(f.editor)){
                res.push(f);
            }
        }
        return res;
    }

    get tabList () {
        let res = [];
        for (let f of this.fields) {
            if (f.editor=='tab'){
                res.push(f);
            }
        }
        return res;
    }

    async setRelationTo (field) {
        if (!field.relation) return;
        if (!field.setRelationTo) return;
        if (!this[field.name]) {
            this[field.setRelationTo] = {};
            return;
        }
        let res = await api.getObjectFromStore(field.relation, this[field.name]);
        if (res) {
            this[field.setRelationTo] = res;
        }
        return { params: [field.setRelationTo], value: res, action: 'set' };
    }

    getMaxLength (fieldName) {
        if (!this.tableSpec) return;
        if (this.tableSpec[fieldName]) {
            return this.tableSpec[fieldName];
        }
    }

    setComputedFields () {
        for (let field of this.fieldsList) {
            if (field.computed) {
                let value = field.computed(this);
                if (value!=null && value!=undefined) {
                    if (!this['$' + field.name]) continue;
                    this['$' + field.name].value = value;
                }
            }
        }
        if (this.hasOwnProperty('$parent')) {
            this.$parent.setComputedFields();
        }
    }

    initComputedGetters () {
        //if (!this.gettersNames) return;
        const proto = Object.getPrototypeOf (this);
        const names = Object.getOwnPropertyNames (proto);
        const getters = names.filter (name => name.substring(0, 3)=='$$_');
        for (let getterName of getters) {
            let name = getterName.replace('$$_', '');
            this._computedGetters[name] = getterName;
            Object.defineProperty(this, name, {
                enumerable : true,
                configurable : true,
                set: (value) => {
                    this.setValue({fieldName: field.name, value});
                },
                get: () => {
                    this.checkCacheFromGetters(name);
                    if (!this['$' + name]) return;
                    return this['$' + name].value;
                }
            });
        }
    }

    checkCacheFromGetters (fieldName) {
        for (let _id in _cache.objects) {
            if (!_cache.objects[_id]) continue;
            let obj = _cache.objects[_id];
            for (let getter in obj) {
                if (getter == fieldName) continue
                if (obj[getter].status) {
                    if (!this['$' + fieldName]) this['$' + fieldName] = {value: null, getters: []}
                    if (!this['$' + fieldName].getters[getter]) this['$' + fieldName].getters[getter] = [];
                    if (this['$' + fieldName].getters[getter].indexOf(_id)==-1) {
                        this['$' + fieldName].getters[getter].push(_id)
                    }
                }
            }
        }
    }


    getComputedGetter (name, getterName) {
        if (!_cache.objects[this._id]) {
            _cache.objects[this._id] = {};
        }
        if (!_cache.objects[this._id][name]) {
            _cache.objects[this._id][name] = {status: false, update: true};
        }
        if (_cache.objects[this._id][name].update) {
            _cache.objects[this._id][name].status = true;
            let r = this[getterName];
            if (!this['$' + name]) this['$' + name] = {value: null, getters: []};
            this['$' + name].value = r;
            this.updateGetters(name)
        }
        _cache.objects[this._id][name].update = false;
        _cache.objects[this._id][name].status = false;
    }


    setComputedGetters (fieldName, updateParent) {
        for (let name in this._computedGetters) {
            if (name == fieldName) continue;
            let getterName = this._computedGetters[name];
            this.getComputedGetter(name, getterName);
        }
        if ((fieldName || updateParent) && this.hasOwnProperty('$parent')) {
            this.$parent.setComputedGetters(null, true); //revisar
        }
    }

    setModified (value) {
        if (this.hasOwnProperty('_modified')) {
            if (this._modified != value) {
                this._modified = value;
                //api.setModified(value);
            }
        } else if (this.hasOwnProperty('$parent')) {
            this.$parent.setModified(value);
        }
    }

    async edit (fieldName, newVal, oldVal) {
        this.setModified(true);
        let field = _.find(this.fieldsList, (c) => c.name == fieldName);
        let r;
        if (field) {
            r = await this.setRelationTo(field);
        }
        return r;
    }

    get getName () {
        if (this.Name) return this.Name;
    }

}

export default BaseModel;
