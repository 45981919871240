<template>
    <section class="home-section" v-if="ready">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="card rounded-0">
                    <tableau :options="options"></tableau>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import tableau from '@/components/tools/Tableau';
export default {
    name: "tableau-report2",
    props: [],
    components: {
        tableau,
    },
    async mounted () {
        let settings = await api.get('/api/get_s3/ui-settings.json');
        if (settings) {
            this.options.url = settings['tableau_url_2'];
            this.title = settings['tableau_title_2']
        }
        this.ready = true;
    },
    data() {
        return {
            ready: false,
            title: null,
            options: {
                url: 'https://prod-useast-a.online.tableau.com/t/tarmac/views/LIDControl/NSxDay?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
                height: '700px',
            },
        };
    },
    methods: {
    },
};
</script>
