<template>
    <section class="home-section">
        <block-ui v-if="processing"/>
        <section-title title="Change Password"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="col-lg-5 my-3">
                    <div class="card rounded-0">
                        <input type="password" class="form-control inpc" v-model="password" name="password"  placeholder="Old Password" >
                    </div>
                    <div class="card rounded-0">
                        <input type="password" class="form-control inpc" v-model="newpassword" name="newpassword"  placeholder="New Password" >
                    </div>
                    <div class="card rounded-0">
                        <input type="password" class="form-control inpc" v-model="newpassword2" name="newpassword2"  placeholder="Re-Enter New Password" >
                    </div>
                    <div class="card rounded-0">
                        <button type="button" id="submit" class="btn btn-primary" @click="change"
                        :disabled="!checkNewPassword || !password">Change Password</button>
                    </div>
                    <div class="card rounded-0">
                        <div v-if="errors.length>0" class="alert alert-danger" role="alert">
                            <div v-for="error of errors">
                                {{ error }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>



<script>
import { mapState } from 'vuex';
export default {
  name: 'change-password',
  data: function () {
    return {
        password: '',
        newpassword: '',
        newpassword2: '',
        errors: [],
        processing: false,
    }
  },
  computed: {
    ...mapState({
        me: state => state.main.me,
    }),
    checkNewPassword: function() {
        return (this.newpassword.length>0 && this.newpassword==this.newpassword2 && this.password!=this.newpassword);
    }
  },
  methods: {
    change: async function() {
        let data = {
            newpassword: this.newpassword,
            newpassword2: this.newpassword2,
            oldpassword: this.password
        }
        let res = await api.post('/change_password', JSON.stringify(data), null, (error) => {
            this.errors.push(error);
        });
        if (res) {
            alert('Password modificado');
            this.$store.commit('setMe', res);
            this.$router.push('/');
        }
    },
    setProcessing: function(value) {
        this.processing = value;
    },
    addError: function  (msg) {
        app.addError(this, msg);
    },

  }
}
</script>

