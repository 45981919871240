<template>
    <section class="home-section">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <report-window
                endpoint="/reports/lid_data"
                :perPage="10"
                :fields="fields"
                :title="title"
                :headers="headers"
                :removeHeaders="removeHeaders"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
                :columnsClasses="columnsClasses"
            ></report-window>
            <flight-info
                v-if="flight"
                :flight="flight"
                @close="flight = null"
            ></flight-info>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import FlightInfo from "@/components/flight/FlightInfo";
const moment = require("moment");
export default {
    name: "lid-data-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
        'flight-info': FlightInfo,
    },
    mounted: function() {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
    },
    data() {
        return {
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: "FileDate",
                    editor: "date",
                    label: "File Date",
                    columns: 2,
                    required: true,
                    defValue: moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightNumber",
                    label: "Flight Designator",
                    columns: 2,
                },
                {
                    name: "FlightFromDate",
                    editor: "date",
                    label: "Flight Date From",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightToDate",
                    editor: "date",
                    label: "Flight Date To",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "Origin",
                    editor: "text",
                    label: "Origin",
                    columns: 2
                },
                {
                    name: "Destination",
                    editor: "text",
                    label: "Destination",
                    columns: 2
                },
            ],
            columnsClasses: {
            },
            title: "Lid Data",
            removeHeaders: [],
            headers: {
            },
            templates: [
                {name: 'File Date', callback: function(row) {
                        if (!row['File Date']) return '';
                        return moment(row['File Date']).format('DD MMM');
                    }
                },
            ],
            columnClick: {
                number: this.showFlight,
            },
            fieldClasses: {
                number: function(row) {
                    return 'cursor-pointer-report';
                },
            },
        };
    },
    methods: {
        showFlight (row) {
            //this.flight = row;
        }
    }
};
</script>
