<template>
    <section class="home-section">
        <block-ui v-if="processing"/>
        <section-title title="Routes"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="col-lg-15 my-3">
                    <div class="card rounded-0">
                        <div class="card-header" style="background-color: #fff">
                          <h6 class="font-weight-bold mb-0">Add Route</h6>
                        </div>
                        <div class="card-body pt-0" v-for="(r, i) of routes">
                            <section class="bg-mix">
                                <div class="">
                                    <div class="border-bottom rounded-0">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Route</h6>
                                                    <input v-model="r.value.market" class="form-control inpc" type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">LF Trigger</h6>
                                                    <input v-model="r.value.lf_trigger" class="form-control inpc" type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">DB Cost</h6>
                                                    <input v-model="r.value.db_cost" class="form-control inpc" type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex my-3">
                                                <div class="mx-left my-3">
                                                    <button class="btn btn-bd" @click="routes.splice(i, 1)">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="add">{{tr('New Route')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="save">{{tr('Save')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'routes',
    data () {
        return {
            processing: false,
            routes: [],
        }
    },
    async mounted () {
        this.processing = true;
        let routes = await api.get('/api/get_s3/whitelist_legs.json');
        this.routes = [];
        for (let r of routes) {
            this.routes.push({value: r});
        }
        this.processing = false;
    },
    methods: {
        async save () {
            this.processing = true;
            let routes = [];
            for (let r of this.routes) {
                routes.push(r.value);
            }
            let res = await api.post('/api/save_s3/whitelist_legs.json', JSON.stringify(routes));
            this.processing = false;
        },
        add () {
            this.routes.push({value: {}});
        },
    },
}
</script>

