<template>
    <section class="home-section">
        <block-ui v-if="processing"/>
        <section-title title="Rules"></section-title>
        <section class="bg-mix">
            <div class="container">
                <div class="col-lg-15 my-3">
                    <div class="card rounded-0">
                        <div class="card-header" style="background-color: #fff">
                          <h6 class="font-weight-bold mb-0">Add Rules</h6>
                        </div>
                        <div class="form-group col-md-4 mt-1" >
                            <input class="form-control mr-sm-2" type="search" :placeholder="tr('Search')"
                                aria-label="Search" v-model="search">
                        </div>
                        <div class="card-body pt-0" v-for="(rule, i) of rules">
                            <section class="bg-mix" v-if="filter(rule)">
                                <div class="">
                                    <div class="border-bottom rounded-0">
                                        <div class="row">
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">From Flight</h6>
                                                    <input
                                                        v-model="rule.from_flight"
                                                        class="form-control inpc"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">To Flight</h6>
                                                    <input
                                                        v-model="rule.to_flight"
                                                        class="form-control inpc"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Origin</h6>
                                                    <input
                                                        v-model="rule.from_station"
                                                        class="form-control inpc"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Destination</h6>
                                                    <input
                                                        v-model="rule.to_station"
                                                        class="form-control inpc"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">From Date</h6>
                                                    <date-picker
                                                        :def.sync="rule.from_date"
                                                        :id="i+'-from_date'"
                                                    ></date-picker>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">To Date</h6>
                                                    <date-picker
                                                        :def.sync="rule.to_date"
                                                        :id="i+'to_date'"
                                                    ></date-picker>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Lid</h6>
                                                    <number-input
                                                        :v.sync="rule.lid"
                                                        class="form-control inpc"
                                                    ></number-input>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Lid %</h6>
                                                    <input
                                                        v-model="rule['lid (%)']"
                                                        class="form-control inpc"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Status</h6>
                                                    <input
                                                        v-model="rule.status"
                                                        class="form-control inpc w-50"
                                                        type="checkbox">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Last Flight</h6>
                                                    <input
                                                        v-model="rule.last_flight"
                                                        class="form-control inpc w-50"
                                                        type="checkbox">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Exclude</h6>
                                                    <input
                                                        v-model="rule.exclude"
                                                        class="form-control inpc w-50"
                                                        type="checkbox">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Rule Name</h6>
                                                    <input
                                                        v-model="rule.comment"
                                                        class="form-control inpc"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">cap</h6>
                                                    <number-input
                                                        :v.sync="rule.cap"
                                                        class="form-control inpc"
                                                    ></number-input>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex stat my-3">
                                                <div class="mx-left">
                                                    <h6 class="text-muted">Days of Week</h6>
                                                    <input
                                                        v-model="rule.days_of_week"
                                                        class="form-control inpc"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex my-3">
                                                <div class="mx-left my-3">
                                                    <button class="btn btn-br" @click="showMarkets(i)">Markets</button>
                                                </div>
                                            </div>
                                            <div class="col-lg-2 col-md-6 d-flex my-3">
                                                <div class="mx-left my-3">
                                                    <button class="btn btn-bd" @click="rules.splice(i, 1)">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div class="card-body pt-0">
                            <button class="btn btn-bb  btn-sm btn-rule" @click="add">{{tr('New Rule')}}</button>
                            <button class="btn btn-bb  btn-sm btn-save" @click="save">{{tr('Save')}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="ruleMarkets" tabindex="-1" role="dialog" aria-labelledby="ruleMarkets"
                data-backdrop="static" aria-hidden="true" v-if="showRuleMarkets">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">{{rules[currentRule].comment}}</h5>
                    <button type="button" class="close" @click="close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" >
                      <div class="row">
                          <div class="col-4" v-for="m of rules[currentRule].markets">
                              <div class="row py-1">
                                  <input
                                      v-model="m.value"
                                      class="form-control inpc w-25"
                                      type="checkbox">
                                  <h6 class="text-muted">{{m.market}}</h6>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>

        </section>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'rules',
    data () {
        return {
            processing: false,
            rules: [],
            markets: [],
            search: '',
            showRuleMarkets: false,
            currentRule: null,
        }
    },
    async mounted () {
        this.processing = true;
        this.rules = await api.get('/api/get_s3/rules.json');
        this.markets = await api.get('/api/get_markets');
        this.processing = false;
    },
    methods: {
        async save () {
            this.processing = true;
            let res = await api.post('/api/save_s3/rules.json', JSON.stringify(this.rules));
            this.processing = false;
        },
        add () {
            this.rules.push({
                from_flight: null,
                to_flight: null,
                from_station: null,
                to_station: null,
                from_date: null,
                to_date: null,
                status: false,
                lid: null,
                markets: null,
                cap: null,
                days_of_week: null,
            });
        },
        filter (rule) {
            if (this.search && this.search.length>0) {
                let values = this.search.split(' ')
                for (let value of values){
                    let found = false;
                    let re = new RegExp(tools.normalize(value), 'i')
                    for (let f of ['from_flight', 'to_flight', 'from_station', 'to_station', 'comment']) {
                        let v = rule[f];
                        if (!v) v = '';
                        let m = tools.normalize(v).match(re)
                        if (m) found = true;
                    }
                    if (!found) return false;
                }
                return true;
            }
            return true;
        },
        async showMarkets (i) {
            this.currentRule = i;
            this.showRuleMarkets = true;
            if (!this.rules[i].markets) {
              this.rules[i].markets = [];
              for (let m of this.markets) {
                this.rules[i].markets.push({market: m, value: true})
              }
            } else {
              for (let m of this.markets) {
                let found = _.find(this.rules[i].markets, (r) => r.market == m);
                let value = true;
                let falseFound = _.find(this.rules[i].markets, (r) => !r.value)
                if (falseFound) value = false;
                if (!found) this.rules[i].markets.push({market: m, value: value});
              }
            }
            await this.$nextTick();
            $('#ruleMarkets').modal('show');
        },
        close () {
            this.currentRule = null;
            this.showRuleMarkets = false;
            $('#ruleMarkets').modal('hide');
        }
    },
}
</script>

