<template>
    <section :class="{'home-section': !cardView, 'card-section': cardView}">
        <section-title title="Market Summary" v-if="!cardView">
        </section-title>
        <div class="home-content font-weight-bold ml-4" v-else>
            Market Summary
        </div>
        <div v-if="ready" class="border-top charts-list" :class="{'charts-card': cardView}">
            <div v-for="v of list" class="">
                <div class="w-100">

                    <aside>
                        <h3 class="ml-2">{{ v.onewaymarket }}</h3>
                        <div>
                            <div v-for="(c, i) of chartOptions" class="">
                                <bar-chart :chart-data="chart(c, v.rows)"  :options="barChartOptions[i]" :height="cardView? '100%': 60"
                                ></bar-chart>
                            </div>
                        
                        </div>
                    </aside>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
const colors = ['#EC7063', '#45B39D', '#F4D03F', '#85C1E9', '#BB8FCE', '#E5E7E9', '#2C3E50', '#FDFEFE']
import barChart from '@/tools/BarChart.js'
export default {
    name: "market-summary-report",
    props: ["options"],
    components: {
        'bar-chart': barChart,
    },
     mounted: async function() {
        await this.run()
        this.ready = true;
    },
    data() {
        return {
            ready: false,
            list: [],
            chartOptions: [
                {
                    y: 'Date',
                    x: ['No Show', 'OVBK Seats'],
                    types: [{type: 'line', fill: false}],
                    colors: [colors[2], colors[1]]
                },
                {
                    y: 'Date',
                    x: ['Spoil seats'],
                    colors: [colors[0]]
                },
                {
                    y: 'Date',
                    x: ['LF'],
                    colors: [colors[3]]
                },
            ]
        };
    },
    computed: {
        barChartOptions () {
            let values = {};
            for (let i in this.chartOptions) {
                let chartOptions = this.chartOptions[i];
                let res = {
                tooltips: {
                    callbacks: {
                        label: (tooltipItem, data) => {
                            let indexName = chartOptions.x[tooltipItem.datasetIndex];
                            var label = data.datasets[tooltipItem.datasetIndex].label || '';
                            if (label) {
                                label += ': ';
                            }
                            let value = tools.toNumber(tooltipItem.yLabel, 0);
                            if (chartOptions.beforeLabel && chartOptions.beforeLabel[indexName]) {
                                label += chartOptions.beforeLabel[indexName] + value;
                            }
                            if (chartOptions.afterLabel && chartOptions.afterLabel[indexName]) {
                                label += value + chartOptions.afterLabel[indexName];
                            }
                            return label;
                        }
                    }
                }
            };
            values[i] = res;
          };
          return values;
        },
        cardView () {
            if (this.options && this.options.cardView) return true;
        },
    },
    methods: {
        async run () {
            //let routes = await api.get('/api/get_s3/whitelist_legs.json');
            this.dataList = [];
            if (this.requestTimeout) api.requestTimeout = this.requestTimeout;
            api.setProcessing(true);
            this.running = true;
            let res = await api.get('/reports/markets_summary', {}, (err) => {

            });
            api.requestTimeout = null;
            if (res) {
                this.list = res;
            }
            api.setProcessing(false);
            this.running = false;
        },
        chart (chart, dataChart) {
          return {
            labels: this.chartDataList(chart, dataChart),
            datasets: this.chartDataValues(chart, dataChart)
          }
        },
        chartDataList (chartOptions, dataChart) {
            //let template = _.find(this.templates, (t) => t.name==chartOptions.y);
            //let self = this;
            let res = _.map(dataChart, (r) => {
                  let v = r[chartOptions.y];
                  //let row = {};
                  /*if (template) {
                      row[chartOptions.y] = r[chartOptions.y];
                      v = template.callback(row, self.dataList, chartOptions.y);
                  }*/
                  return v;
              });
            return res;
        },
        chartDataValues (chartOptions, dataChart) {
          let res = [];
          if (!chartOptions) return res;
          for (let i in chartOptions.x) {
            let color = colors[i];
            let r = {
                label: [chartOptions.x[i]],
                backgroundColor: color,
                data: _.map(dataChart, (r) => {
                  return r[chartOptions.x[i]]
                }),
                scales: {
                    xAxes: [{
                        ticks: {
                            display: false // Hide x-axis labels
                        },
                        label: {
                            display: false,
                        }
                    }],
                },
            }
            if (chartOptions.types && chartOptions.types[i]) {
                r.type = chartOptions.types[i].type;
                r.fill = chartOptions.types[i].fill;
            }
            if (chartOptions.colors && chartOptions.colors[i]) {
                r.backgroundColor = chartOptions.colors[i];
            }
            res.push(r)
          }
          return res;
        },
    }
};
</script>

<style scoped>
.home-content {
    height: 45px !important;
}
.rotate {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 50% 0%;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.w-5 {
    width: 5%;
}
.w-95 {
    width: 95%;
}
.w-100 {
}


aside {
  display: block;
  position: relative;
  margin: 10px 20px;
}

aside h3 {
  font: bold 12px Sans-Serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0px 0px 0px 10px;
  margin: 150px 0 0 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: 0 100%;
  transform: rotate(-90deg);
  background-color: white;
  background: white;

}

/* Class name via Modernizr */
.aside {
  border-left: 34px solid #369;
  padding-left: 10px;
}

.card-section {
}

.charts-card {
    max-height: 540px;
    overflow-y: scroll;

}

.charts-list {
    background-color: white;
}
 </style>