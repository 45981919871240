<template>
    <section class="home-section">
        <section-title :title="title"></section-title>
        <section class="bg-mix">
            <report-window
                endpoint="/reports/rec_data"
                :perPage="10"
                :fields="fields"
                :title="title"
                :headers="headers"
                :removeHeaders="removeHeaders"
                :current.sync="current"
                :columnClick="columnClick"
                :templates="templates"
                :fieldClasses="fieldClasses"
                :columnsClasses="columnsClasses"
            ></report-window>
            <flight-info
                v-if="flight"
                :flight="flight"
                @close="flight = null"
            ></flight-info>
        </section>
    </section>
</template>

<script>
import ReportWindow from "@/components/tools/ReportWindow";
import FlightInfo from "@/components/flight/FlightInfo";
const moment = require("moment");
export default {
    name: "rec-data-report",
    props: ["options"],
    components: {
        'report-window': ReportWindow,
        'flight-info': FlightInfo,
    },
    mounted: function() {
        for (let field of this.fields) {
            if (field.defValue) {
                this.current[field.name] = field.defValue;
            } else {
                this.current[field.name] = null;
            }
        }
        if (this.options) {
            for (let field of this.fields) {
                if (this.options[field.name]) {
                    field.defValue = this.options[field.name];
                    this.current[field.name] = this.options[field.name];
                }
            }
        }
        this.fields = Object.assign([], this.fields);
    },
    data() {
        return {
            current: {},
            flight: null,
            ready: false,
            fields: [
                {
                    name: "FileDate",
                    editor: "date",
                    label: "File Date",
                    columns: 2,
                    required: true,
                    defValue: moment(new Date()).add(-1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightNumber",
                    label: "Flight Designator",
                    columns: 2,
                },
                {
                    name: "FlightFromDate",
                    editor: "date",
                    label: "Flight Date From",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "FlightToDate",
                    editor: "date",
                    label: "Flight Date To",
                    columns: 2,
                    defValue: moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
                },
                {
                    name: "Origin",
                    editor: "text",
                    label: "Origin",
                    columns: 2
                },
                {
                    name: "Destination",
                    editor: "text",
                    label: "Destination",
                    columns: 2
                },
                {
                    name: "Lids",
                    editor: "select",
                    label: "Show",
                    columns: 2,
                    options: [
                        { label: 'All', value: '0'}, { label: 'Sent Lids', value: '1' }
                    ],
                    defValue: '1'
                },
            ],
            columnsClasses: {
                esperanza_pnr: 'text-right',
                varianza_pnr: 'text-right',
                Media: 'text-right',
                Pax: 'text-right',
                Sigma: 'text-right',
                LF: 'text-right',
                LF_Trigger: 'text-right',
                AP7Rev: 'text-right',
                OB: 'text-right',
                DB_Cost: 'text-right',
                Raw_Lid: 'text-right',
                Cap: 'text-right',
                NDO: 'text-right',
                Lid_max: 'text-right',
                exc: 'text-right',
            },
            title: "Recommendations Data",
            removeHeaders: ['File Date', 'IATA_Code', 'Flight'],
            headers: {
                "Step1": "Route",
                "FlightDate": "Flight Date",
                "FlightDesignator": "Flight Designator",
                "esperanza_pnr": "Esp",
                "varianza_pnr": "Var",
            },
            templates: [
                {name: 'Lid_max', callback: function(row) {
                        if (!row.Lid_max) return '';
                        return parseInt(row.Lid_max);
                    }
                },
                {name: 'Lid', callback: function(row) {
                        if (!row.Lid) return '';
                        return parseInt(row.Lid);
                    }
                },
                {name: 'esperanza_pnr', callback: function(row) {
                        if (!row.esperanza_pnr) return '';
                        return (parseFloat(row.esperanza_pnr)).toFixed(1);
                    }
                },
                {name: 'varianza_pnr', callback: function(row) {
                        if (!row.varianza_pnr) return '';
                        return (parseFloat(row.varianza_pnr)).toFixed(1);
                    }
                },
                {name: 'LF', callback: function(row) {
                        if (!row.LF) return '';
                        return (parseFloat(row.LF) * 100).toFixed(0) + ' %';
                    }
                },
                {name: 'LF_Trigger', callback: function(row) {
                        if (!row.LF_Trigger) return '';
                        return (parseFloat(row.LF_Trigger) * 100).toFixed(0) + ' %';
                    }
                },
                {name: 'Sigma', callback: function(row) {
                        if (!row.Sigma) return '';
                        return (parseFloat(row.Sigma)).toFixed(2);
                    }
                },
                {name: 'Media', callback: function(row) {
                        if (!row.Media) return '';
                        return (parseFloat(row.Media)).toFixed(2);
                    }
                },
                {name: 'AP7Rev', callback: function(row) {
                        if (!row.AP7Rev) return '';
                        return (parseFloat(row.AP7Rev)).toFixed(2);
                    }
                },
                {name: 'Cap_Date', callback: function(row) {
                        if (!row.Cap_Date) return '';
                        return moment(row.Cap_Date).format('DD MMM HH:mm');
                    }
                },
                {name: 'FlightDate', callback: function(row) {
                        if (!row.FlightDate) return '';
                        return moment(row.FlightDate).format('DD MMM');
                    }
                },
                {name: 'File Date', callback: function(row) {
                        if (!row['File Date']) return '';
                        return moment(row['File Date']).format('DD MMM');
                    }
                },

            ],
            columnClick: {
                number: this.showFlight,
            },
            fieldClasses: {
                number: function(row) {
                    return 'cursor-pointer-report';
                },
            },
        };
    },
    methods: {
        showFlight (row) {
            //this.flight = row;
        }
    }
};
</script>
